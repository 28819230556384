import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Card = styled.div`
  width: 284px;
  min-height: 128px;
`

const ContactCard = ({ headline, subline }) => {
  return (
    <a className="lg:pointer-events-none" href={"tel:" + subline}>
      <Card className="flex flex-col items-start justify-between rounded-xl bg-blue-background px-5 py-4 shadow-none hover:shadow-dropdown transition-shadow">
        <h6 className="font-extended text-p2 text-center text-blue font-extrabold">{headline}</h6>
        <p className="text-p3 text-gray-400 text-center">{subline}</p>
      </Card>
    </a>
  )
}

ContactCard.propTypes = {
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
}

export default ContactCard

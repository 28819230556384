import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import PalButton from "../../../components/buttons/PalButton"
import Container from "../../../components/containers/Container"
import { handleScrollToElement } from "../../../utils/helpers"
import { useReverseLanguage } from "../../../components/hooks/useReverseLanguage"

const HeroContainer = styled.div`
  height: 720px;

  @media (max-width: 640px) {
    height: 640px;
  }
`

const HeadlineIcon = ({ content }) => {
  const { formatMessage } = useIntl()

  const { headline, subline, icon, buttonText, backgroundColor, anchor, alt } = content

  return (
    <Container className="relative flex justify-center items-center">
      <div className={"absolute z-0 h-full w-screen " + backgroundColor} />
      <HeroContainer className="relative flex flex-col-reverse lg:flex-row justify-center items-center w-full" style={{ maxWidth: 934 }}>
        <div
          className={
            "relative w-full flex flex-col items-center lg:items-start lg:w-1/2 text-center " +
            (useReverseLanguage() ? "lg:text-right" : "lg:text-left")
          }
        >
          <h1 className="text-h4 md:text-h2 text-blue">{formatMessage({ id: headline })}</h1>
          {subline && <h1 className="text-h4 md:text-h2 text-blue">{formatMessage({ id: subline })}</h1>}
          <div className="space-y-4 xl:space-y-0 xl:gap-x-4 flex sm:hidden flex-col xl:flex-row w-56 mt-16">
            <PalButton action={() => handleScrollToElement(anchor, 800)} label={formatMessage({ id: buttonText })} color="primary" block />
          </div>
        </div>
        <div className="h-auto w-full lg:w-1/2 relative my-12 lg:my-0 flex justify-center lg:justify-end items-center">
          <img alt={alt} src={icon} className="w-32 sm:w-44 lg:w-auto" />
        </div>
      </HeroContainer>
    </Container>
  )
}

HeadlineIcon.propTypes = {
  content: PropTypes.object.isRequired,
}

export default HeadlineIcon

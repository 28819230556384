import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect } from "react"

// Components
import SEO from "../components/SEO"
import HeadlineIcon from "../modules/HeroMedia/variants/HeadlineIcon"
import TextBlock from "../modules/TextBlock/TextBlock"
import ContactCard from "../components/ContactCard"
import Container from "../components/containers/Container"

// Helpers & Content
import { contactContent } from "../utils/content/contactContent"
import { fireEvent } from "../utils/helpers"

// markup
const ContactPage = () => {
  const { seo, mainHero, contactCards } = contactContent
  const { formatMessage } = useIntl()

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  return (
    <div>
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} themeColor="#E3EFFE" />
      <HeadlineIcon content={mainHero} />
      <TextBlock>
        <h5 className="text-blue">{formatMessage({ id: "contactSubtextOne" })}</h5>
        <p className="text-d2">{formatMessage({ id: "contactSubtextTwo" })}</p>
      </TextBlock>
      <Container id="page-content" className="flex justify-center items-center pb-32 md:pb-40">
        <div className="flex flex-col lg:flex-row lg:flex-wrap justify-start items-center gap-10" style={{ maxWidth: 932 }}>
          {contactCards.map(card => (
            <ContactCard key={card.headline} headline={card.headline} subline={card.subline} />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default ContactPage

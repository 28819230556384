import React from "react"
import styled from "styled-components"

import Container from "../../components/containers/Container"

const TextBlockWrapper = styled.div`
  & h6 {
    line-height: 30px;
    font-weight: normal;
  }

  & h6,
  & p {
    color: #4a505e;
  }

  & h4 {
    font-family: Neue Plak Extended;
    color: #0e1a50;
    margin-top: 64px !important;
  }
`

const TextBlock = ({ children, ...props }) => {
  return (
    <Container {...props} className="flex justify-center py-20 sm:py-28 lg:py-40">
      <TextBlockWrapper className="w-full space-y-8" style={{ maxWidth: 860 }}>
        {children}
      </TextBlockWrapper>
    </Container>
  )
}

export default TextBlock

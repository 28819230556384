import icon from "../../assets/images/contact/illuBig.svg"

export const contactContent = {
  seo: {
    metaTitle: "metaTitleContact",
    metaDescription: "metaDescriptionContact",
  },
  mainHero: {
    headline: "contactMainHeroHeadline",
    subline: "contactMainHeroSubline",
    icon: icon,
    alt: "tier-contact-icon",
    buttonText: "readMore",
    backgroundColor: "bg-blue-background",
    anchor: "#page-content",
  },
  contactCards: [
    {
      headline: "Austria",
      subline: "+43 720 817110",
    },
    {
      headline: "Denmark",
      subline: "+45 78 75 00 53",
    },
    {
      headline: "Doha",
      subline: "+800101233",
    },
    {
      headline: "Finland",
      subline: "+358 9 425 50519",
    },
    {
      headline: "France",
      subline: "+33 186 76 72 83",
    },
    {
      headline: "Germany",
      subline: "+49 30 568 386 51",
    },
    {
      headline: "Norway",
      subline: "+47 235 065 74",
    },
    {
      headline: "Poland",
      subline: "+48 225 81 8133",
    },
    {
      headline: "Slovakia",
      subline: "+42 123 278 4669",
    },
    {
      headline: "Sweden",
      subline: "+46 40 668 81 27",
    },
    {
      headline: "Switzerland",
      subline: "+41 43 505 14 62",
    },
    {
      headline: "United Arab Emirates",
      subline: "+971 80 003 20857",
    },
    {
      headline: "United Kingdom",
      subline: "+44 80 8164 9486",
    },
  ],
}
